module.exports = [
  {
    component: 'PlainText',
    label: 'Adverse Event >',
    layout: {
      display: 'block',
      fontWeight: 'bold',
      paddingTop: '10px',
      transition: 'transform 100ms ease',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.1)'
      }
    },
    triggers: {
      onClick: ['onNavigateToSubcase', 'ae']
    }
  },
  {
    component: 'LabelValue',
    label: '',
    value: '(NEW)',
    pristine: true,
    triggers: {
      onClick: ['onNavigateToSubcase', 'ae']
    },
    layout: {
      display: 'flex',
      flexDirection: 'row'
    },
    styles: {
      background: 'transparent',
      borderColor: 'transparent',
      width: 'inherit',
      fontSize: '20px',
      fontFamily: 'SackersGothicStd-Heavy',
      textAlign: 'inherit'
    },
    referencedProperties: {
      value: {
        $compose: [
          { $get: 'subcases.adverseEvent.status' },
          {
            $or: [
              {
                $and: [{ $eq: 'NEW' }, { $set: '(NEW)' }]
              },
              {
                $and: [{ $eq: 'IN_PROGRESS' }, { $set: '(IN PROGRESS)' }]
              },
              {
                $and: [{ $eq: 'ARCHIVED' }, { $set: '(ARCHIVED)' }]
              },
              {
                $and: [{ $eq: 'COMPLETED' }, { $set: '(COMPLETED)' }]
              },
              {
                $set: ''
              }
            ]
          }
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'AE Owner: ',
    statePath: 'subcases.adverseEvent.assigneeName',
    pristine: true,
    layout: {
      width: '100%',
      ' > span span:nth-child(2)': {
        cursor: 'pointer',
        fontWeight: 'bold'
      }
    },
    triggers: {
      onClick: ['onAssignSubcase', 'ae']
    },
    valueTemplate: '<%= getUserName(users, value) %>',
    referencedProperties: {
      "layout[' > span span:nth-child(2)'].cursor": {
        schemaPath: './',
        relative: true,
        $or: [
          {
            $if: [
              { 'data.isCaseLocked': { $eq: true } },
              { $set: 'not-allowed' }
            ]
          },
          {
            $if: [
              { 'data.roles': { $not: { $contains: 'edit-ae' } } },
              { $set: 'not-allowed' }
            ]
          },
          {
            $if: [
              { $get: 'model.subcases.adverseEvent.assigneeName' },
              { $set: 'initial' }
            ]
          },
          {
            $if: [
              { 'model.subcases.adverseEvent.status': { $eq: 'ARCHIVED' } },
              { $set: 'not-allowed' }
            ]
          },
          { $set: 'pointer' }
        ]
      },
      "layout[' > span span:nth-child(2)'].fontWeight": {
        $if: [
          { $get: 'subcases.adverseEvent.assigneeName' },
          { $set: 'initial' }
        ],
        $else: { $set: 'bold' }
      },
      "layout[' > span span:nth-child(2)'].color": {
        schemaPath: './',
        relative: true,
        $if: [
          {
            $or: [
              { 'data.isCaseLocked': { $eq: true } },
              { 'data.roles': { $not: { $contains: 'edit-ae' } } }
            ]
          },
          { $set: '#9B9B9B' }
        ],
        $else: { $set: 'initial' }
      },
      'triggers.onClick': {
        schemaPath: './',
        relative: true,
        $or: [
          { $if: [{ 'data.isCaseLocked': { $eq: true } }, { $set: '' }] },
          {
            $if: [
              { 'data.roles': { $not: { $contains: 'edit-ae' } } },
              { $set: '' }
            ]
          },
          {
            $if: [
              { $get: 'model.subcases.adverseEvent.assigneeName' },
              { $set: '' }
            ]
          },
          { $set: ['onAssignSubcase', 'ae'] }
        ]
      },
      disabled: {
        schemaPath: './',
        relative: true,
        $if: [
          {
            $or: [
              { '/data.isCaseLocked': { $eq: true } },
              { '/data.roles': { $not: { $contains: 'edit-ae' } } }
            ]
          },
          { $set: true }
        ],
        $else: { $set: false }
      },
      valueTemplate: {
        $or: [
          {
            $and: [
              { 'subcases.adverseEvent.assigneeName': { $ne: null } },
              {
                $concat: [
                  '<%=data.userMap["',
                  { $get: 'subcases.adverseEvent.assigneeName' },
                  '"] || "',
                  { $get: 'subcases.adverseEvent.assigneeName' },
                  '"%>'
                ]
              }
            ]
          },
          'Assign to me'
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Seriousness:',
    pristine: true,
    layout: {
      width: '100%'
    },
    referencedProperties: {
      value: {
        $or: [
          {
            $if: [
              {
                $or: [
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'death'
                    }
                  },
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'life_threat'
                    }
                  },
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'hospital'
                    }
                  },
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'prolonged_hospital'
                    }
                  },
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'disabling_incapacitating'
                    }
                  },
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'cong_birth_anomaly'
                    }
                  },
                  {'subcases.adverseEvent.aerinfo.safety.seriousness': {
                    $eq: 'other'
                    }
                  },

                ]
                
              },
              { $set: 'Serious' }
            ]
          },
          {
            $if: [
              {
                'subcases.adverseEvent.aerinfo.safety.seriousness': {
                  $eq: 'nonserious'
                }
              },
              { $set: 'Non Serious' }
            ]
          },
          {
            $if: [
              {
                'summary.narrative.seriousness': {
                  $eq: 'serious'
                }
              },
              { $set: 'Serious' }
            ]
          },
          { $set: 'Not Specified' }
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Due Date:',
    statePath: 'aerinfo.safety.submission_due_date',
    layout: {
      width: '100%'
    },
    valueTemplate: '<%= triggers.getAbbvieDate(value) %>',
    referencedProperties: {
      valueTemplate: {
        $or: [
          {
            $and: [
              {
                'subcases.adverseEvent.aerinfo.safety.submission_due_date': {
                  $ne: null
                }
              },
              {
                $concat: ['<%=triggers.getAbbvieDate(value) || value %>']
              }
            ]
          },
          'Not Specified'
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'LRD:',
    statePath: 'aerinfo.safety.last_received_date',
    layout: {
      width: '100%'
    },
    valueTemplate: '<%= triggers.getAbbvieDate(value) %>',
    referencedProperties: {
      valueTemplate: {
        $or: [
          {
            $and: [
              {
                'subcases.adverseEvent.aerinfo.safety.last_received_date': {
                  $ne: null
                }
              },
              {
                $concat: ['<%=triggers.getAbbvieDate(value) || value %>']
              }
            ]
          },
          'Not Specified'
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Case Type:',
    value: 'Not Specified',
    pristine: true,
    layout: {
      width: '100%'
    },
    referencedProperties: {
      value: {
        $if: [
          {
            $and: [
              {
                $or: [
                  {
                    'subcases.adverseEvent.aerinfo.safety.version': {
                      $eq: 'initial_case'
                    }
                  },
                  {
                    'summary.narrative.adverse_event.type': {
                      $eq: 'initial_case'
                    }
                  }
                ]
              },
              {
                'subcases.adverseEvent.aerinfo.safety.version': {
                  $ne: 'follow_up'
                }
              }
            ]
          },
          { $set: 'Initial' }
        ],
        $elseIf: [
          {
            $or: [
              {
                'subcases.adverseEvent.aerinfo.safety.version': {
                  $eq: 'follow_up'
                }
              },
              {
                'summary.narrative.adverse_event.type': {
                  $eq: 'follow_up'
                }
              }
            ]
          },
          { $set: 'Follow-up' }
        ],
        $else: { $set: 'Not Specified' }
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Submitted?:',
    pristine: true,
    layout: {
      width: '100%'
    },
    referencedProperties: {
      value: {
        statePath: 'subcases.adverseEvent.submitted',
        $if: [{ $eq: true }, { $set: 'Yes' }],
        $else: { $set: 'No' }
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Reconciliation Needed:',
    pristine: true,
    layout: {
      width: '100%',
      '> span span:nth-child(2)': {
        fontWeight: 'bold'
      }
    },
    referencedProperties: {
      value: {
        statePath: 'subcases.adverseEvent.reconciliationNeeded',
        $if: [{ $eq: true }, { $set: 'True' }],
        $else: { $set: false }
      },
      'layout.display': {
        schemaPath: './',
        relative: true,
        $if: [
          { 'model.subcases.adverseEvent.reconciliationNeeded': { $eq: true } },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  }
];
